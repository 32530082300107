import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import { ContainerSVGT, MainFont, SubFont, SermonsColor, FourthPage} from '../components/Sampler'
import HistoryPhoto from '../../static/img/uploads/History Photo.jpg'
import HistoryMap from '../../static/img/uploads/History Map.jpg'
import HistoryNews from '../../static/img/uploads/History Newspaper.jpg'
import {
  prayerhandsSVGT1,
  prayerhandsSVGT2,
  xmarkSVGT1,
  xmarkSVGT2
} from '../../static/svgtext'
import remark from 'remark'
import remarkHTML from 'remark-html'
import remarkParse from 'remark-parse'

import PlayButton from '../../static/img/Play Button.png'
import PauseButton from '../../static/img/Pause Button.png'
import DownloadButton from '../../static/img/Download Button.png'
import SoundIcon from '../../static/img/Sound Icon.png'
import PassageIcon from '../../static/img/Passage Icon.png'
import SpeakerIcon from '../../static/img/Speaker Icon.png'

export const SermonsPageTemplate = ({ 
  backgroundimage,
  sermonAudio,
}) => {
  const [sermonArray, setSermonArray] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [rateNumber, setRateNumber] = useState(1)
  const [playBuffer, setPlayBuffer] = useState(true)
  const [audioPercent, setAudioPercent] = useState(0)
  const [progressTime, setProgressTime] = useState(0)
  const [totalTime, setTotalTime] = useState(0)
  const [subPage, setSubPage] = useState(1)

  var satitle = (sermonAudio !== null && FourthPage === 'Sermons' && sermonAudio.length > 0) ? sermonAudio[0].title : "Refresh the Page"
  var sadate = (sermonAudio !== null && FourthPage === 'Sermons' && sermonAudio.length > 0) ? sermonAudio[0].date : null
  var sapassage = (sermonAudio !== null && FourthPage === 'Sermons' && sermonAudio.length > 0) ? sermonAudio[0].passage : null
  var saspeaker = (sermonAudio !== null && FourthPage === 'Sermons' && sermonAudio.length > 0) ? sermonAudio[0].speaker : null
  var safile = (sermonAudio !== null && FourthPage === 'Sermons' && sermonAudio.length > 0) ? sermonAudio[0].file : null

  const [selectedSermonName, setselectedSermonName] = useState(satitle)
  const [selectedSermonDate, setSelectedSermonDate] = useState(sadate)
  const [selectedSermonPassage, setSelectedSermonPassage] = useState(sapassage)
  const [selectedSermonSpeaker, setSelectedSermonSpeaker] = useState(saspeaker)
  const [selectedSermonAudioSource, setSelectedSermonAudioSource] = useState(safile)

  const audioRef = useRef()

  const volumeBar = typeof document !== 'undefined' ? document.getElementById('volumeBar') : null
  const progressBar = typeof document !== 'undefined' ? document.getElementById('progressBar') : null
  const progressBackground = typeof document !== 'undefined' ? document.getElementById('progressBackground') : null
  const mainName = typeof document !== 'undefined' ? document.getElementById('mainName') : null
  const sermonPlayer = typeof document !== 'undefined' ? document.getElementById('sermonPlayer') : null

  const WindowCheck = () => {
    checkScroll()
  }

  if (typeof window !== 'undefined') {
    window.addEventListener('resize', WindowCheck)
  }

  useEffect(() => {
    // Run once when component is mounted
    if (sermonAudio !== null && FourthPage === 'Sermons' && sermonAudio.length > 0) {
      setSermonArray(sermonAudio)
    }
  }, [])

  useEffect(() => {
    if (sermonAudio !== null && FourthPage === 'Sermons' && sermonAudio.length > 0) {
      // Run when selectedSermonName changes
      if (selectedSermonName) {
        audioRef.current.src = selectedSermonAudioSource
        checkScroll()
        if (!playBuffer) {
          setIsPlaying(true)
        }
      }
    }
  }, [selectedSermonName])

  useEffect(() => {
    if (sermonAudio !== null && FourthPage === 'Sermons' && sermonAudio.length > 0) {
      // when isPlaying changes, update audio
      if (isPlaying) {
        audioRef.current.play()
      } else {
        audioRef.current.pause()
      }
    }
  }, [isPlaying, selectedSermonName])


  useEffect(() => {
    if (progressBackground != null) {
      progressBackground.style.width = (progressTime / totalTime) * 100 + '%'
    }
  }, [progressTime])

  const checkScroll = () => {
    if (mainName != null && sermonPlayer != null) {
      if (mainName.className == 'Name' && mainName.className != 'scrollName') {
        mainName.style.marginRight = '0px'
        if (mainName.clientWidth > (sermonPlayer.clientWidth - 308)) {
          mainName.classList.remove('Name')
          mainName.classList.add('scrollName')
          mainName.style.marginRight = -(mainName.clientWidth / 2 - (Number(mainName.style.marginRight.substr(0, (mainName.style.marginRight.length - 2)) / 2)) - (sermonPlayer.clientWidth - 308)) + 'px'
        }
      } else if (mainName.className == 'scrollName' && mainName.className != 'Name') {
        mainName.style.marginRight = -(mainName.clientWidth / 2 - (Number(mainName.style.marginRight.substr(0, (mainName.style.marginRight.length - 2)) / 2)) - (sermonPlayer.clientWidth - 308)) + 'px'
        if (mainName.clientWidth / 2 - (Number(mainName.style.marginRight.substr(0, (mainName.style.marginRight.length - 2)) / 2)) < (sermonPlayer.clientWidth - 308)) {
          mainName.classList.remove('scrollName')
          mainName.classList.add('Name')
          mainName.style.marginRight = '0px'
        }
      }
    }
  }

  const updateMetaData = () => {
    setTotalTime(audioRef.current.duration)
  }

  const setProgressBar = () => {
    if (audioRef.current) {
      setProgressTime(Math.floor(audioRef.current.currentTime))
      setAudioPercent(audioRef.current.currentTime / audioRef.current.length)
    }
  }

  const togglePlay = () => {
    setIsPlaying(!isPlaying)
  }

  const toggleRate = () => {
    const rate = audioRef.current.playbackRate
    if (rate == 1) {
      audioRef.current.playbackRate = 1.25
      setRateNumber(1.25)
    } else if (rate == 1.25) {
      audioRef.current.playbackRate = 1.5
      setRateNumber(1.5)
    } else if (rate == 1.5) {
      audioRef.current.playbackRate = 2
      setRateNumber(2)
    } else if (rate == 2) {
      audioRef.current.playbackRate = 0.75
      setRateNumber(0.75)
    } else if (rate == 0.75) {
      audioRef.current.playbackRate = 1
      setRateNumber(1)
    }
  }

  const subPageRight = () => {
    if (subPage < (sermonArray.length / 10)) {
      setSubPage(subPage + 1)
    }
  }
  const subPageLeft = () => {
    if (subPage > 1) {
      setSubPage(subPage - 1)
    }
  }

  const toHTML = value => remark()
    .use(remarkParse)
    .use(remarkHTML)
    .processSync(value)
    .toString()

  return (
    <div>
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: FourthPage === 'History' ? `url("/img/uploads/History Sample.jpg")` : `url(${!!backgroundimage.childImageSharp ? backgroundimage.childImageSharp.fluid.src : backgroundimage
            })`,
        }}
      >

        {/* Font Imports */}
        <link
          href="https://fonts.googleapis.com/css2?family=IM+Fell+English&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
          rel="stylesheet"
        ></link>
        <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700;900&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.cdnfonts.com/css/bahnschrift" rel="stylesheet"></link>

        {/* SERMONS IMAGE HEADING */}
        <div style={{ display: 'grid' }}>

          {/* Colored Style Container */}
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            height="310px" viewBox="0 0 1563.000000 1525.000000"
            preserveAspectRatio="xMidYMid meet"
            style={{
              gridRow: '1 / 1',
              gridColumn: '1 / 1',
            }}>

            <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
              fill={SermonsColor} stroke="none">
              <path d={ContainerSVGT} />
            </g>
          </svg>

          {/* "Sermons" Text */}
          <div style={{
            gridRow: '1 / 1',
            gridColumn: '1 / 1',
            alignSelf: 'center',
            textAlign: 'center',
            fontSize: '3.2em',
            color: '#FFFFFF',
            fontFamily: MainFont,
            fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
            textTransform: MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'uppercase' : null,
            letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
          }}>
            {FourthPage.toString().toUpperCase()}
          </div>
        </div>
      </div>

      {/* SERMONS OPTION */}
      {FourthPage === 'Sermons' ? 

        <div>

          {/* SERMONS LIST */}
          {(sermonAudio !== null && sermonAudio.length > 0) ?
            <section className='section' style={{padding: 0}}>
                  {/* {sermonAudio.map((item) => (
                    <div className='event-pane-box'>
                      <h1 className="title is-size-3 has-text-weight-bold is-bold-light"
                        style={{
                          marginTop: '0px',
                          marginBottom: '5px',
                          color: '#B77D2C',

                          // fontSize: '2.4em',
                          // color: 'white',
                          // fontFamily: 'IM Fell English'
                        }}
                      >
                        {item.title}
                      </h1>
                      <div className='event-pane'>
                        <div style={{
                          minWidth: '300px',
                        }}>
                          <p className='event-pane-date' style={{ fontWeight: 'normal', fontSize: '20px', marginBottom: '10px' }}>{item.date} {item.time === null ? null : item.time}</p>
                          {item.image === null || item.image === undefined ? <div /> :
                            <img className='event-pane-image' src={item.image.childImageSharp ? item.image.childImageSharp.fluid.src : item.image} alt="Image" />
                          }
                        </div>

                        <div className='event-pane-text'>
                          <PageContent className="content" content={toHTML(item.description)} />
                        </div>
                      </div>
                    </div>
                  ))} */}

                  <div className="Page">

                    <br />

                    {/* MAIN SERMON PLAYER HEADER */}
                    <div className='PodcastPlayer' id='sermonPlayer' onLoadCapture={checkScroll}>
                      {/* INVISIBLE AUDIO FILE */}
                      <audio onTimeUpdate={setProgressBar} preload="metadata" onLoadedMetadata={updateMetaData} ref={audioRef} type='audio' />

                      <img />

                      {/* MAIN PLAY BUTTON */}
                      <div className='SermonLeftSide'>
                        <button className='MainPlayButton' onClick={togglePlay}>
                          <img src={isPlaying ? PauseButton : PlayButton} className='ButtonIcon' />
                        </button>

                        <div className='NameAndDate' style={{
                          fontFamily: MainFont,
                          fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                          textTransform: MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'uppercase' : null,
                          letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
                        }}>
                          {/* SERMON NAME */}
                          <p id='mainName' className='Name'>{selectedSermonName}</p>

                          {/* DATE POSTED */}
                          <p className='Date'>Preached {selectedSermonDate}</p>
                        </div>
                      </div>

                      <div className='ExtraControls'>
                        {/* PASSAGE BUTTON */}
                        <a className='PassageButton' href={"https://www.biblegateway.com/passage/?search="+selectedSermonPassage} target="_blank" data-title={selectedSermonPassage} style={{
                          fontFamily: SubFont,
                        }}>
                          <img className='PassageIcon' src={PassageIcon} />
                        </a>

                        {/* SPEAKER BUTTON */}
                        <a className='SpeakerButton' data-title={selectedSermonSpeaker} style={{
                          fontFamily: SubFont,
                        }}>
                          <img className='SpeakerIcon' src={SpeakerIcon} />
                        </a>

                        {/* VOLUME BAR */}
                        <div className='VolumeAndIcon'>
                          <img className='SoundIcon' src={SoundIcon} />
                          <input className='Volume' id='volumeBar' type='range' min='0' max='1' step='0.01' onInput={() => audioRef.current.volume = volumeBar.value} />
                        </div>

                        <div className='TimerAndRate' style={{
                          fontFamily: SubFont,
                        }}>
                          {/* PROGRESS TIMER */}
                          <p className='SermonProgressTimer'>
                            {/* Current Time: */}{/*Hours*/}{Math.floor(progressTime / 3600) > 0 ? Math.floor(progressTime / 3600) + ':' : null}{/*Minutes*/}{((Math.floor((progressTime / 60) - (60 * (Math.floor(progressTime / 3600)))) < 10) && (Math.floor(progressTime / 3600) > 0)) ? 0 : null}{Math.floor((progressTime / 60) - (60 * (Math.floor(progressTime / 3600))))}:{/*Seconds*/}{Math.floor(progressTime - (60 * (Math.floor(progressTime / 60)))) < 10 ? 0 : null}{Math.floor(progressTime - (60 * (Math.floor(progressTime / 60))))}
                            /
                            {/* Total Time: */}{/*Hours*/}{Math.floor(totalTime / 3600) > 0 ? Math.floor(totalTime / 3600) + ':' : null}{/*Minutes*/}{((Math.floor((totalTime / 60) - (60 * (Math.floor(totalTime / 3600)))) < 10) && (Math.floor(totalTime / 3600) > 0)) ? 0 : null}{Math.floor((totalTime / 60) - (60 * (Math.floor(totalTime / 3600))))}:{/*Seconds*/}{Math.floor(totalTime - (60 * (Math.floor(totalTime / 60)))) < 10 ? 0 : null}{Math.floor(totalTime - (60 * (Math.floor(totalTime / 60))))}
                          </p>

                          {/* PLAYBACK RATE BUTTON */}
                          <button className='RateButton' onClick={toggleRate}>
                          <p className='RateNumber' style={{
                            fontFamily: SubFont,
                          }}>
                            {rateNumber}x
                          </p>
                          </button>
                        </div>
                      </div>

                      {/* DOWNLOAD BUTTON */}
                      <a className='SermonDownloadButton' href={selectedSermonAudioSource} download={selectedSermonName}>
                        <img className='DownloadIcon' src={DownloadButton} />
                      </a>
                      <img />

                      {/* PROGRESS BAR */}
                      <div className='SermonProgressHolder'>
                        <input className='Progress' id='progressBar' type='range' min='0' max={totalTime} step='0.01' value={progressTime} onInput={() => { audioRef.current.currentTime = progressBar.value; progressBackground.style.width = (progressBar.value / totalTime) * 100 + '%' }} />
                      </div>
                      <div className='SermonProgressFill' id='progressBackground' style={{
                        backgroundColor: SermonsColor
                      }}/>


                    </div>

                    <div className='Divider'>~</div>

                    {sermonArray && sermonArray.map((title, q) => {

                      const isCurrentlyPlaying = isPlaying && selectedSermonName === sermonArray[q].title

                      if ((q <= (subPage * 10 - 1)) && (q >= (subPage - 1) * 10)) {
                        return (
                          <div key={sermonArray[q].title} className='PodcastListing' style={{
                            fontFamily: SubFont,
                          }}>
                            <button className='SubButton' style={{ display: 'inline-block' }} onClick={(selectedSermonName === sermonArray[q].title) ? togglePlay : () => { setselectedSermonName(sermonArray[q].title); setSelectedSermonDate(sermonArray[q].date); setSelectedSermonPassage(sermonArray[q].passage); setSelectedSermonSpeaker(sermonArray[q].speaker); setSelectedSermonAudioSource(sermonArray[q].file); setPlayBuffer(false) }}>
                              <img src={isCurrentlyPlaying ? PauseButton : PlayButton} className='SubButtonIcon' />
                            </button>
                            {/* <p style={{ display: 'inline-block' }}>Ep. {SermonEpisodes.length - q}: {title}</p> */}
                            <p className='ListingName'>{sermonArray[q].title} [{sermonArray[q].passage}]</p>
                          </div>
                        )
                      }
                    }
                    )
                    }

                    {sermonAudio.length > 10 ? (
                      <div>
                        {subPage > 1 ? <button onClick={subPageLeft} className='SubPageArrow'>{"<"}</button> : <img className='InvisibleArrow' />}
                        <p className='SubPageText' style={{
                          fontFamily: SubFont,
                        }}>
                          Page {subPage}
                        </p>
                        {subPage < (sermonAudio.length / 10) ? <button onClick={subPageRight} className='SubPageArrow'>{">"}</button> : <img className='InvisibleArrow' />}
                      </div>)
                      : <div className='BottomBreak' />}

              </div>
            </section >
            :
            <div className='no-events' style={{
              color: SermonsColor
            }}>

              {/* Google Fonts Import */}
              <link
                href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
                rel="stylesheet"
              ></link >
              <svg className="x-mark" version="1.0" xmlns="http://www.w3.org/2000/svg"
                height="710.000000pt" viewBox="0 0 709 710" 
                preserveAspectRatio="xMidYMid meet"
                style={{
                  gridRow: '1 / 1',
                  gridColumn: '1 / 1',
                }}
              >
                <g transform="translate(0.000000,710.000000) scale(0.100000,-0.100000)"
                  fill={SermonsColor} stroke="none">
                  <path d={xmarkSVGT1} />
                  <path d={xmarkSVGT2} />
                </g>
              </svg>
              <p>
                There are no<br></br>sermons to show<br></br>at this time.<br></br><br></br>Stay tuned.
              </p>
            </div>
          }
        </div>  
      
      // PRAYER
      : FourthPage === 'Prayer' ?
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="section">

                  <h2 className="title is-size-3 has-text-weight-bold is-bold-light"
                    style={{
                      display: 'flex',
                      margin: 'auto',
                      marginBottom: '20px',
                      justifyContent: 'center',
                      alignContent: 'center'
                    }}
                  >
                    How can we pray for you?
                  </h2>
                  <div class="content">
                    <p><em>I&#xFEFF;f you don't record your sermon audio but you want to use your website as part of your prayer ministry, you can use this page to encourage visitors to send in their prayer requests online. You can say whatever you want below, and even edit the title above, while the button at the bottom of the page sends them to the contact forms where they can email you. Here's an example of something you can say:</em></p>
                    <p>At Poplar Branch Baptist Church, we believe prayer is an essential part of living out our mission as the body of Christ and growing in our relationship with the Lord (1 Chronicles 16:11, Ephesians 6:18). To respect the privacy of those who have entrusted their prayer requests to us, we do not post prayers on our website. If we can pray for you, or if you would like to share a praise, please reach out through the Contact page of our site. If you don’t want your request or praise to be shared with the whole church, please indicate that in your form. </p>
                    <p><em>“Since we have a great high priest who has passed through the heavens, Jesus the Son of God… let us confidently approach the throne of grace to receive mercy and find grace whenever we need help!” (Hebrews 4:14-16)</em></p>
          

                    <Link to="/contact" title="ContactLink">
                      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                        className="link-button"
                        alt="Hands"
                        height="202.72px" viewBox="0 0 443.000000 439.000000"
                        preserveAspectRatio="xMidYMid meet"
                        style={{
                          gridRow: '1 / 1',
                          gridColumn: '1 / 1',
                          marginLeft: '3px',
                          marginTop: '-3px',
                          height: '150px',
                          display: 'flex',
                          margin: 'auto',
                          marginTop: '40px',
                          marginBottom: '10px',
                          padding: '10px',
                          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                        }}
                      >
                        <g transform="translate(1.000000,410.000000) scale(0.152000,-0.152000)"
                          fill={SermonsColor} stroke="none">
                          <path d={prayerhandsSVGT1} />
                          <path d={prayerhandsSVGT2} />
                        </g>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
      // HISTORY
      :
        <div class="container">
          <div class="columns">
            <div class="column is-10 is-offset-1">
              <div class="section">
                <h2 class="title is-size-3 has-text-weight-bold is-bold-light">
                  OUR HISTORY
                </h2>
                <div class="content">
                  <p><em>I&#xFEFF;f your church doesn't livestream or record its services, you can use this page to tell visitors about its history instead. You have a lot of flexibility with this. You can make it short or long, add pictures, headings, links, and don't forget - you can change any of it whenever you want!</em></p>
                  <h3>FOUNDERS</h3>
                  <div><img src={HistoryPhoto} alt="" title="John and Jane Doe" data-reactroot="" /></div>
                  <p>Here are some people we'll pretend are your church founders. And here's where you can write information about them. You can tell us why they started the church, or maybe how it went at first. What's the legacy that your church gets to be a part of?</p>
                  <h3>P&#xFEFF;LACES</h3>
                  <div><img src={HistoryMap} alt="" title="" data-reactroot="" /></div>
                  <p>If the history of your church is connected to the history of your town, you could consider showing some old maps and explaining how they both grew alongside each other. Maybe you how an old sketch of the church building when it was being constructed, or maybe you want to talk about the local network of churches that the first pastor started and how you’re still a part of it today.</p>
                  <h3>TIMELINE</h3>
                  <div><img src={HistoryNews} alt="" title="" data-reactroot="" /></div>
                  <p>Whether it's newspaper clippings or your own custom timeline, you could show your website visitors what's happened with the church at key points in its history!</p>
                  <p>R&#xFEFF;eally, you can do so much a history page, it's up to you what you think is important for visitors to know. You're just a couple of clicks and paragraphs away from making it just how you want it. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

SermonsPageTemplate.propTypes = {
  backgroundimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  sermonAudio: PropTypes.array,
}

const SermonsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <SermonsPageTemplate
        backgroundimage={frontmatter.backgroundimage}
        sermonAudio={frontmatter.sermonAudio}
      />
    </Layout>
  )
}

SermonsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default SermonsPage

export const SermonsPageQuery = graphql`
  query SermonsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "sermons-page" } }) {
      html
      frontmatter {
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sermonAudio {
          title
          date
          passage
          speaker
          file
        }
      }
    }
  }
`